/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import Router from "next/router";
import { httpBatchLink, loggerLink, TRPCClientError } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";

import { type AppRouter } from "@gility/api";
import { transformer } from "@gility/api/transformer";

const getBaseUrl = () => {
  if (typeof window !== "undefined") return ""; // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url

  return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};

export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      transformer,
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === "development" ||
            (opts.direction === "down" && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
        }),
      ],
      queryClientConfig: {
        defaultOptions: {
          queries: {
            retry: (failureCount, error) => {
              if (handleErrorsOnClient(error)) return false;
              return failureCount < 3;
            },
          },
          mutations: {
            retry: (_, error) => {
              handleErrorsOnClient(error);
              return false;
            },
          },
        },
      },
    };
  },
  ssr: false,
});

const handleErrorsOnClient = (error: unknown): boolean => {
  if (typeof window === "undefined") return false;
  if (!(error instanceof TRPCClientError)) return false;

  if (["FORBIDDEN", "UNAUTHORIZED"].includes(error?.data?.code)) {
    void Router.push("/");
  }

  return true;
};

/**
 * Inference helpers for input types
 * @example type HelloInput = RouterInputs['example']['hello']
 **/
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helpers for output types
 * @example type HelloOutput = RouterOutputs['example']['hello']
 **/
export type RouterOutputs = inferRouterOutputs<AppRouter>;
