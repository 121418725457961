import { useEffect } from "react";
import { setUser } from "@sentry/nextjs";
import { useSession } from "next-auth/react";

const SentryUserManager = () => {
  const { data: session } = useSession();

  useEffect(() => {
    if (session?.user) {
      setUser({
        id: session.user.id,
      });
    } else {
      setUser(null);
    }
  }, [session?.user]);
  return null;
};

export default SentryUserManager;
