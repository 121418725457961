import path from "path";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend/cjs";
import LocalStorageBackend from "i18next-localstorage-backend";
import i18nConfig from "@gility/next-i18next-config";

/** @type {import("next-i18next").UserConfig} */
const config = {
  backend: {
    backendOptions: [
      { expirationTime: process?.env?.NODE_ENV === "production" ? 5 * 60 * 1000 : 1 },
    ], // 5m in production
    backends: typeof window !== "undefined" ? [LocalStorageBackend, HttpBackend] : [],
  },
  ...i18nConfig,
  localePath: path.resolve("./public/locales"),
  reloadOnPrerender: process?.env?.NODE_ENV === "development",
  serializeConfig: false,
  use: typeof window !== "undefined" ? [ChainedBackend] : [],
};

export default config;
