declare const window: Window & { dataLayer?: Record<string, unknown>[] };

export const pageview = (url: string) => {
  window.dataLayer?.push({
    event: "pageview",
    page: url,
  });
};

export const hsForm = (formName: string, privacy: boolean, email?: string | null) => {
  window.dataLayer?.push({
    event: `hs_form_${formName}`,
    privacy: privacy ? "yes" : "no",
    email,
  });
};

export const event = (name: string) => {
  window.dataLayer?.push({
    event: name,
  });
};
